export const sideMenu = [
  {
    menuItem: "Бар",
    menuLink: "bar",
  },
  {
    menuItem: "Снеки",
    menuLink: "snacks",
  },
  {
    menuItem: "Кухня",
    menuLink: "kitchen",
  },
  {
    menuItem: "Соуси",
    menuLink: "souses",
  },
];
