import burger from "../galary/menu/kitchen/burgerche.jpg";
import bigBurger from "../galary/menu/kitchen/big-burger.jpg";
import deeps from "../galary/menu/kitchen/deeps.webp";
import cheez from "../galary/menu/kitchen/cheez.PNG";
import kingkrevetki from "../galary/menu/kitchen/kingkrevetki.jpg";
import mozarela from "../galary/menu/kitchen/mozarela.jpg";
import nagets from "../galary/menu/kitchen/nagets.webp";
import onion from "../galary/menu/kitchen/onion.jpg";
import pankokrevet from "../galary/menu/kitchen/pankokrevet.PNG";
import popcorn from "../galary/menu/kitchen/popcorn.jpg";
import strips from "../galary/menu/kitchen/strips.jpg";
import wings from "../galary/menu/kitchen/wings.jpg";
import filadelfia from "../galary/menu/kitchen/filadelfia.jpg";
import fri from "../galary/menu/kitchen/fri.jpg";
import burgerMenu from "../galary/menu/kitchen/burger-menu.png";

export const menu = [
	{
		id: 201,
		category: "Кухня",
		subCategory: "Бургери",
		name: "Міні бургер",
		image: burger,
		description: "яловичина, чедер, мариновані огірки і цибуля, соус бургерний",
		price: 69,
	},
	{
		id: 202,
		category: "Кухня",
		subCategory: "Бургери",
		name: "Бургер",
		image: burger,
		description: "яловичина, чедер, айсберг, мариновані огірки і цибуля, соус бургерний",
		price: 112,
	},
	{
		id: 203,
		category: "Кухня",
		subCategory: "Бургери",
		name: "Біг Бургер",
		image: bigBurger,
		description: "яловичина, чедер, помідор, айсберг, мариновані огірки і цибуля, соус бургерний",
		price: 159,
	},
	{
		id: 204,
		category: "Кухня",
		subCategory: "Бургери",
		name: "Мега Бургер",
		image: bigBurger,
		description: "яловичина, чедер, мариновані огірки і цибуля, кетчуп, соус бургерний",
		price: 179,
	},
	{
		id: 205,
		category: "Кухня",
		subCategory: "Бургери",
		name: "Рибний Бургер",
		image: burger,
		description: "Філе риби, чедер, айсберг, соус тартар",
		price: 179,
	},
	{
		id: 206,
		category: "Кухня",
		subCategory: "Бургери",
		name: "Міні курячий",
		image: burger,
		description: "Куряче філе, айсберг, мариновані огірки і цибуля, соус бургерний, соус сирний",
		price: 69,
	},
	{
		id: 207,
		category: "Кухня",
		subCategory: "Бургери",
		name: "Курячий бургер",
		image: burger,
		description: "Куряче філе, айсберг, мариновані огірки і цибуля, соус бургерний, соус сирний",
		price: 119,
	},
	{
		id: 208,
		category: "Кухня",
		subCategory: "Бургери",
		name: "Бургер барбекю",
		image: burger,
		description: "Яловичина, бекон, цибулеві кільця, чедер, помідор, мариновані огірки і цибуля, соус BBQ",
		price: 159,
	},
	{
		id: 209,
		category: "Кухня",
		subCategory: "Бургери",
		name: "Сирний бургер",
		image: burger,
		description: "Яловичина, чедер, моцарела, гауда, мариновані огірки і цибуля, соус бургерний",
		price: 129,
	},
	{
		id: 210,
		category: "Кухня",
		subCategory: "Бургери",
		name: "Мисливський",
		image: burger,
		description: "Яловичина, чедер, ковбаски, салямі, мариновані огірки і цибуля, гірчиця, соус бургерний",
		price: 129,
	},
	{
		id: 211,
		category: "Кухня",
		subCategory: "Бургер-меню",
		name: "Бургер меню",
		image: burgerMenu,
		description: "Бургер, картопля фрі, напій на вибір",
		price: 189,
	},
	{
		id: 212,
		category: "Кухня",
		subCategory: "Бургер-меню",
		name: "Біг бургер меню",
		image: burgerMenu,
		description: "Біг Бургер, картопля фрі, напій на вибір",
		price: 239,
	},
	{
		id: 213,
		category: "Кухня",
		subCategory: "Бургер-меню",
		name: "Сирний бургер меню",
		image: burgerMenu,
		description: "Сирний бургер, картопля фрі, напій на вибір",
		price: 199,
	},
	{
		id: 214,
		category: "Кухня",
		subCategory: "Бургер-меню",
		name: "Курячий бургер меню",
		image: burgerMenu,
		description: "Курячий бургер, картопля фрі, напій на вибір",
		price: 189,
	},
	{
		id: 215,
		category: "Кухня",
		subCategory: "Бургер-меню",
		name: "Мега бургер меню",
		image: burgerMenu,
		description: "Мега бургер, картопля фрі, напій на вибір",
		price: 259,
	},
	{
		id: 216,
		category: "Кухня",
		subCategory: "Бургер-меню",
		name: "Бургер барбекю меню",
		image: burgerMenu,
		description: "Бургер барбекю, картопля фрі, напій на вибір",
		price: 239,
	},
	{
		id: 217,
		category: "Кухня",
		subCategory: "Бургер-меню",
		name: "Мисливський бургер меню",
		image: burgerMenu,
		description: "Мисливський бургер, картопля фрі, напій на вибір",
		price: 229,
	},
	{
		id: 218,
		category: "Кухня",
		subCategory: "Фритюр",
		name: "Картопля фрі",
		image: fri,
		weight: "150 грам",
		price: 60,
	},
	{
		id: 219,
		category: "Кухня",
		subCategory: "Фритюр",
		name: "Картопляні діпи",
		image: deeps,
		weight: "150 грам",
		price: 69,
	},
	{
		id: 220,
		category: "Кухня",
		subCategory: "Фритюр",
		name: "Нагетси",
		image: nagets,
		weight: "6 шт",
		price: 79,
	},
	{
		id: 221,
		category: "Кухня",
		subCategory: "Фритюр",
		name: "Нагетси",
		image: nagets,
		weight: "9 шт",
		price: 109,
	},
	{
		id: 222,
		category: "Кухня",
		subCategory: "Фритюр",
		name: "Моцарела фрі",
		image: mozarela,
		weight: "5 шт",
		price: 129,
	},
	{
		id: 223,
		category: "Кухня",
		subCategory: "Фритюр",
		name: "Сирні палички",
		image: cheez,
		weight: "5 шт",
		price: 69,
	},
	{
		id: 224,
		category: "Кухня",
		subCategory: "Фритюр",
		name: "Сирні палички",
		image: cheez,
		weight: "10 шт",
		price: 129,
	},
	{
		id: 225,
		category: "Кухня",
		subCategory: "Фритюр",
		name: "Попкорн",
		image: popcorn,
		weight: "100 грам",
		price: 49,
	},
	{
		id: 226,
		category: "Кухня",
		subCategory: "Фритюр",
		name: "Попкорн",
		image: popcorn,
		weight: "200 грам",
		price: 89,
	},
	{
		id: 227,
		category: "Кухня",
		subCategory: "Фритюр",
		name: "Креветки панко",
		image: pankokrevet,
		weight: "5 шт",
		price: 79,
	},
	{
		id: 228,
		category: "Кухня",
		subCategory: "Фритюр",
		name: "Стріпси",
		image: strips,
		weight: "5 шт",
		price: 112,
	},
	{
		id: 229,
		category: "Кухня",
		subCategory: "Фритюр",
		name: "Стріпси",
		image: strips,
		weight: "10 шт",
		price: 209,
	},
	{
		id: 230,
		category: "Кухня",
		subCategory: "Фритюр",
		name: "Креветки королівські",
		image: kingkrevetki,
		weight: "5 шт",
		price: 169,
	},
	{
		id: 231,
		category: "Кухня",
		subCategory: "Фритюр",
		name: "Цибулеві кільця",
		image: onion,
		weight: "6 шт",
		price: 69,
	},
	{
		id: 232,
		category: "Кухня",
		subCategory: "Фритюр",
		name: "Цибулеві кільця",
		image: onion,
		weight: "9 шт",
		price: 89,
	},
	{
		id: 233,
		category: "Кухня",
		subCategory: "Фритюр",
		name: "Крильця",
		image: wings,
		weight: "5 шт",
		price: 129,
	},
	{
		id: 234,
		category: "Кухня",
		subCategory: "Фритюр",
		name: "Крильця",
		image: wings,
		weight: "10 шт",
		price: 229,
	},
	{
		id: 235,
		category: "Кухня",
		subCategory: "Фритюр",
		name: "Кульки філадельфія",
		image: filadelfia,
		weight: "6 шт",
		price: 69,
	},
	{
		id: 236,
		category: "Кухня",
		subCategory: "Фритюр",
		name: "Кульки філадельфія",
		image: filadelfia,
		weight: "10 шт",
		price: 109,
	},
];
