import vechirka from "./InstaCard/AdobeStock_206053893.webp";
import sport from "./InstaCard/AdobeStock_313425029.webp";
import beers from "./InstaCard/amie-johnson-VJXLzQi5TlE-unsplash 1.webp";
import halloween from "./InstaCard/photo_2023-09-06_13-55-20.webp";

export const data = [
  {
    id: 1,
    image: vechirka,
    alt: "vechirka",
  },
  {
    id: 2,
    image: beers,
    alt: "beers",
  },
  {
    id: 3,
    image: sport,
    alt: "sport",
  },
  {
    id: 4,
    image: halloween,
    alt: "halloween",
  },
];
