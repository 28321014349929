export const fromRigth = {
  visible: {
    x: 0,
  },
  hidden: {
    x: 200,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    y: "100%",
    transition: {
      duration: 0.2,
    },
  },
};
