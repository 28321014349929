import beerCup from "../galary/menu/beer/beer.webp";
import seedr from "../galary/menu/beer/seedr.webp";
import prosecco from "../galary/menu/beer/prosecco.webp";
import kvas from "../galary/menu/beer/kvas.webp";

export const beer = [
	{
		id: 100,
		category: "Напої",
		subCategory: "Пиво",
		name: "Кельш",
		color: "Світле",
		alcohol: 4.2,
		ibu: 12,
		weight: "0,5л",
		image: beerCup,
		description: "Пиво «Kölsch» має злегка гіркий смак, відчувається хміль.",
		price: 49,
	},
	{
		id: 101,
		category: "Напої",
		subCategory: "Пиво",
		name: "Пшеничне",
		color: "Світле",
		alcohol: 4.5,
		ibu: 13,
		weight: "0,5л",
		image: beerCup,
		description: "Світле нефільтроване непастеризоване пиво «Пшеничне».",
		price: 54,
	},
	{
		id: 102,
		category: "Напої",
		subCategory: "Пиво",
		name: "Лагер",
		color: "Світле",
		alcohol: 4.4,
		ibu: 15,
		weight: "0,5л",
		image: beerCup,
		description: "Світле нефільтроване непастеризоване пиво «Lager».",
		price: 51,
	},
	{
		id: 103,
		category: "Напої",
		subCategory: "Пиво",
		name: "IPA",
		color: "Світле",
		alcohol: 5.6,
		ibu: 45,
		weight: "0,5л",
		image: beerCup,
		description: "Світле нефільтроване непастеризоване пиво «IPA», має легкий солодовий смак.",
		price: 61,
	},
	{
		id: 104,
		category: "Напої",
		subCategory: "Пиво",
		name: "Лайт",
		color: "Світле",
		alcohol: 4.2,
		ibu: 12,
		weight: "0,5л",
		image: beerCup,
		description: "Світле нефільтроване пиво «Light beer», має слабкий солодовий смак.",
		price: 49,
	},
	{
		id: 105,
		category: "Напої",
		subCategory: "Сідр",
		name: "Сідр яблучний",
		color: "Світле",
		alcohol: 5.0,
		ibu: "",
		weight: "0,5л",
		image: seedr,
		description: "Сідр яблучний",
		price: 50,
	},
	{
		id: 106,
		category: "Напої",
		subCategory: "Вино",
		name: "Prosecco",
		color: "Світле",
		alcohol: 10.5,
		ibu: "",
		weight: "0,5л",
		image: prosecco,
		description: "Prosecco",
		price: 70,
	},
	{
		id: 107,
		category: "Напої",
		subCategory: "Квас",
		name: "Квас",
		color: "Світле",
		alcohol: "",
		ibu: "",
		weight: "0,5л",
		image: kvas,
		description: "Квас",
		price: 30,
	},
];
